body {
  margin: 0;
  color: white;
}

body.light-theme {
  background-color: #f0f0f0;
  color: #333;
}

body.dark-theme {
  background-color: #252725;
  color: white;
}

.App-header {
  min-height: 3em;
  padding: 1em;
  padding-left: 2em;
}

.App-header.light-theme {
  background-color: #e0e0e0;
}

.App-header.dark-theme {
  background-color: #3e4237;
}
