@import "~@fontsource/cascadia-code/index.css"; // Weight 400.

.SummaryTree {
    font-family: 'Cascadia Code';

    div {
        overflow-x: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    a {
        color: white;

        &:visited {
            text-decoration-line: line-through;
            text-decoration-color: red;
            text-decoration-style: wavy;
        }

        &:active {
            background-color: yellow;
        }
    }

    .To {
        margin-left: 0em;

        // grid-column: 1;
        .Domain {
            &::before {
                content: '├';
            }

            &:last-child {
                &::before {
                    content: '└';
                }

                .From {
                    &::before {
                        content: ' ├'
                    }

                    &:last-child {
                        &::before {
                            content: ' └';
                        }
                    }
                }
            }

            .From {
                &::before {
                    content: '|├'

                }

                &:last-child::before {
                    content: '|└';
                }
            }
        }
    }

}

body.light-theme .SummaryTree a {
    color: #333;
}

body.dark-theme .SummaryTree a {
    color: white;
}
